<template>
  <div>
    <div class="title">招聘职位</div>

    <el-divider></el-divider>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
      </div>
      <div class="filter_btns">
        <div
          class="custom_button no_select"
          @click="$router.push({ name: 'RecruitmentEditor' })"
        >
          添加
        </div>
      </div>
    </div>

    <div class="post" v-for="item in jobList" :key="item.id">
      <div class="post_info">
        <div class="post_info_title">{{ item.name }}</div>
        <div class="post_info_date">
          招聘时间：{{ item.recruit_time }} <span>{{ item.statusDup }}</span>
        </div>
        <div class="post_info_content">
          <div>{{ item.experienceDup }}</div>
          <el-divider direction="vertical"></el-divider>
          <div>{{ item.degreeDup }}</div>
          <el-divider direction="vertical"></el-divider>
          <div>{{ item.jobTypeDup }}</div>
        </div>
      </div>
      <div class="post_progress">
        <div class="post_progress_title">招聘情况</div>
        <div class="post_progress_content">
          <div>
            计划招聘<span>{{ item.need_num }}</span
            >人
          </div>
          <div>
            招聘中<span>{{ item.recruit_nmn }}</span
            >人
          </div>
          <div>
            已入职<span>{{ item.entry_num }}</span
            >人
          </div>
          <div>
            招聘进度<span
              >{{ ((item.entry_num / item.need_num) * 100).toFixed(2) }}%</span
            >
          </div>
        </div>
      </div>
      <!-- 1=展示2=待审核；3=拒绝；4=已关闭 -->
      <div class="post_btns" v-show="item.status == 1">
        <div
          class="custom_button plain no_select"
          @click="$router.push('/recruitment/recruitmentEditor/' + item.id)"
        >
          编辑
        </div>
        <div
          class="custom_button plain no_select"
          @click="toStop(item.id, item.status === 1)"
        >
          {{ item.status === 1 ? "停止" : "开启" }}
        </div>
      </div>
      <div class="post_btns" v-show="item.status != 1">
        <div class="custom_button plain no_select" style="border: none"></div>
        <div></div>
        <div class="custom_button plain no_select" style="border: none"></div>
      </div>
    </div>
    <div v-if="jobList.length === 0" style="margin: 30px 20px">暂无数据</div>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      // 搜索参数
      search: {
        keyword: "",
      },
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      jobList: [],
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions("recruitment", ["getJobList", "getJobDisable"]),

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keywords = keyword.trim();
      }
      return () => {
        params.page = this.page.page;
        let loading = this.$loading();
        this.getJobList(params)
          .then((res) => {
            console.log(res);
            this.jobList = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      // 生产带有搜索参数的函数
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },

    /**
     * 关闭职位
     */
    toStop(id, isStop) {
      let loading = this.$loading();
      this.getJobDisable({
        id,
        isStop,
      })
        .then((res) => {
          this.$message.success(res.data.msg);
          this.$nextTick(() => {
            loading.close();
            this.loadData();
          });
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(e.message);
          this.$nextTick(() => {
            loading.close();
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.post {
  height: 140px;
  background: #ffffff;
  border: 1px solid #f0f4f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin: 20px 0;

  .post_info {
    .post_info_title {
      font-size: 20px;
      font-weight: bold;
      color: #1c2438;
    }

    .post_info_date {
      font-size: 14px;
      color: #495060;
      margin: 15px 0;
    }

    .post_info_content {
      font-size: 14px;
      color: #495060;
      display: flex;
      align-items: center;
    }
  }

  .post_progress {
    border-left: 1px solid #e1e6eb;
    padding-left: 30px;
    .post_progress_title {
      font-size: 16px;
      color: #1c2438;
    }

    .post_progress_content {
      margin-top: 20px;
      display: flex;
      font-size: 14px;
      color: #80848f;
      > div {
        margin-right: 30px;

        span {
          color: #4db9d5;
        }
      }
    }
  }

  .post_btns {
    display: flex;

    .custom_button {
      width: 58px;
      height: 32px;
      line-height: 32px;

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
</style>
